<template>
  <a-row id="wrap" type="flex">
    <a-col :flex="7" id="background"></a-col>
    <a-col :flex="2">

      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-form-item label="Номер телефона">
          <InputMask
            class="ant-input"
            :mask="['998 (##) ###-##-##']"
            v-decorator="[
              'Phone',
              { initialValue: 998,
              rules: [{ required: true, message: 'Пожалуйста заполните номер телефона' }] },
            ]"
            @input="handleChange"
            placeholder="XXX (XX) XXX-XX-XX"
          />
        </a-form-item>
        <a-form-item label="Пароль">
          <a-input-password
            v-decorator="[
          'Password',
          { rules: [{ required: true, message: 'Пожалуйста заполните Пароль' }] },
        ]"
            type="password"
          >
            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-checkbox
            v-decorator="[
              'remember', { valuePropName: 'checked', initialValue: true, },
            ]">Запомнить этот компьютер !</a-checkbox>
          <a-button type="primary"
                    html-type="submit" class="login-form-button" :loading="isSubmitting">
            <span v-if="!isSubmitting">Войти</span>
          </a-button>
          <router-link :to="{ name: 'register' }">Зарегистрироваться</router-link>
        </a-form-item>
      </a-form>
    </a-col>
  </a-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TheMask } from 'vue-the-mask';
import { formatResponseValidatorFields } from '../../helpers';

export default {
  components: { InputMask: TheMask },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  computed: mapGetters('auth', ['isSubmitting']),
  methods: {
    ...mapActions('auth', ['submitting']),
    handleChange(value) {
      this.form.setFieldsValue({
        Phone: `998${value}`,
      });
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting(true);
          this.$api.login(values, ({ data }) => {
            this.submitting(false);
            this.$store.dispatch('user/setToken', data);
            this.$store.dispatch('user/setUser', data);
            this.$router.push({ name: 'home' });
          }, ({ data, status }) => {
            this.submitting(false);
            const fields = formatResponseValidatorFields(data, values);
            if (status === 401) {
              this.form.setFields({
                Phone: {
                  value: values.Phone,
                  errors: [new Error('Неверное имя пользователя или пароль')],
                },
              });
            }
            if (status === 422) {
              this.form.setFields(fields);
            }
          });
        }
      });
    },
  },
};
</script>

<style>
#wrap {
  height: 100vh;
}
#background {
  background-image: url("https://images.unsplash.com/photo-1553413077-190dd305871c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1375&q=80");
  background-repeat: no-repeat;
  background-size: cover;
}
.login-form {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35%;
}
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
</style>
